.scroller::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F500;
    border-radius: 5px;
    border-color: red;
    border: 5px;
}

.scroller::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F500;
    border-radius: 5px;
}

.scroller::-webkit-scrollbar-thumb
{
	background-color: rgba(0,0,0,0.5);
    border-radius: 5px;
}